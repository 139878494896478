.layout {
  min-height: 100vh;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 0;
  filter: brightness(0.8);
  img {
    height: 60px;
    width: 60px;
    margin: 0 auto;
    padding: 8px;



  }

  &:hover{
    filter: brightness(1);
    cursor: pointer;
  }
}

.contentWrapper {
  margin: 20px 15px;
  padding: 20px;
  min-height: calc(100vh - 104px);
  background: var(--base-white);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
