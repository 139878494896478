.statsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.statsCard {
  display: flex;
  border: 1px solid lightgrey;
  padding: 2px 10px;
  gap: 4px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;

  > div {
    line-height: normal;
  }
}
